<template>
  <el-dialog
    :visible="isRecordVisible"
    :title="title"
    destroy-on-close
    @close="onClose"
    :close-on-click-modal="false"
    class="record_wrapper"
    @opened="beforeDialogOpen"
    top="1vh"
  >
    <div class="update_time">
      <div class="lastUpdate_time">
        <span>{{ $t('ibProfile.sharedIbHistory.lastUpdate') }}:</span> {{ date }}
      </div>
      <a style="color: #004CB2;" @click="refreshData"
        ><em class="el-icon-refresh-right" /><span>{{ $t('ibProfile.sharedIbHistory.refresh') }}</span></a
      >
    </div>
    <div class="select_sub">
      <div class="title">{{ $t('ibProfile.sharedIbHistory.chooseAccount') }}:</div>
      <el-select v-model="value" @change="onChangeEmail" filterable>
        <el-option style="max-width: 460px;" label="All" value="" />
        <el-option style="max-width: 460px;" v-for="item in sharedIbAccount" :key="item.id" :label="item.email" :value="item.id" />
      </el-select>
    </div>
    <el-timeline class="timeline">
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in displayRecord"
          :key="index"
          icon="el-icon-time"
          :type="item.type"
          color="#E25827"
          size="large"
        >
          <template #dot>
            <img style="width: 12px;height: 12px;" src="@/assets/images/profile/timeline_icon.png" />
          </template>
          <div class="timeline_content_left">
            <div>{{ item.historyDate }}</div>
            <div style="margin-top: 6px;">{{ item.historyTime }}</div>
          </div>

          <div class="timeline_content_right">
            <div class="timeline_content_operateType">
              <span>{{ $t(`ibProfile.sharedIbHistory.${item.operateType}`) }}</span>
              <span v-if="item.operateType === 'withdraw' || item.operateType === 'transfer'">
                :
                {{
                  $t('ibProfile.sharedIbHistory.fromTo', {
                    fromAccount: item.fromAccount,
                    toAccount: item.toAccount,
                    total: item.amount
                  })
                }}</span>
            </div>
            <div class="timeline_content">
              <span>{{ item.email }}</span>
              <span style="margin-left: 12px;">{{ $t('ibProfile.sharedIbHistory.device') }} : {{ item.device }}</span>
            </div>
          </div>
        
        </el-timeline-item>
      </el-timeline>
      <el-divider v-if="!maxVewMore" class="view-more" content-position="center" @click="getMoreData">{{
        $t('ibProfile.sharedIbHistory.viewMore')
      }}</el-divider>
      <el-divider v-else content-position="center">{{ $t('ibProfile.sharedIbHistory.allHistory') }}</el-divider>
    </el-timeline>
  </el-dialog>
</template>

<script>
import { apiGetSubAccountHistoryList } from '@/resource';
export default {
  props: {
    isRecordVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    sharedIbAccount: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      value: '',
      displayRecord: [],
      pageNo: 1,
      pageSize: 50,
      total: 0,
      id: '',
      date: ''
    };
  },
  methods: {
    onClose() {
      this.$emit('update:isRecordVisible', false);
    },
    handleResData(data) {
      const resData = data.map(item => {
        const time = this.$options.filters.dateFormatWithTimeZone(item.createTime).split(' ');
        return {
          historyDate: time[0],
          historyTime: time[1],
          amount: item.eventInfo.amount,
          fromAccount: item.eventInfo.fromAccount,
          toAccount: item.eventInfo.toAccount || item.eventInfo.withdrawMethod,
          email: item.email,
          operateType: item.operateType,
          device: item.device
        };
      });
      this.displayRecord = [...this.displayRecord, ...resData];
    },

    async getHistoryData() {
      const params = { pageNo: this.pageNo, pageSize: this.pageSize, subId: this.id };
      const res = await apiGetSubAccountHistoryList(params);
      if (res.data.success) {
        this.total = res.data.total;
        this.handleResData(res.data.rows);
      }
    },

    async getMoreData() {
      this.pageNo++;
      this.getHistoryData();
    },
    beforeDialogOpen() {
      this.date = this.$options.filters.dateFormatWithTimeZone(new Date().getTime());
      this.displayRecord = [];
      if (this.sharedIbAccount.length > 0) {
        this.pageNo = 1;
        this.getHistoryData();
      }
    },
    async onChangeEmail(value) {
      this.displayRecord = [];
      this.id = value;
      this.pageNo = 1;
      this.getHistoryData();
    },
    async refreshData() {
      this.pageNo = 1;
      this.displayRecord = [];
      this.date = this.$options.filters.dateFormatWithTimeZone(new Date().getTime());
      this.getHistoryData();
      this.$message({ type: 'success', message: this.$t('ibProfile.sharedIbHistory.refreshTips') });
    }
  },

  computed: {
    maxVewMore() {
      return this.pageNo * this.pageSize >= this.total;
    }
  },

  mounted() {}
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/ibProfile/subRecordDialog.scss';
</style>
