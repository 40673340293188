<template>
  <el-dialog :visible="isDeleteVisible" destroy-on-close  top="1vh"
    :close-on-click-modal="false"
    >
    <div style="text-align: center;">{{$t('ibProfile.sharedIbList.deleteTips')}}</div>
    <div class="btn_group">
      <el-button @click="$emit('update:isDeleteVisible', false)">{{$t('ibProfile.sharedIbList.cancel')}}</el-button>
      <el-button @click="deleteSharedAccount" type="primary">{{$t('ibProfile.sharedIbList.delete')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { apiPostSubAccountChange } from '@/resource';
export default {
  props: {
    isDeleteVisible: {
      type: Boolean,
      default: false
    },
    updateId: {
      type: Number | String,
      default: ''
    }
  },
  methods: {
    deleteSharedAccount() {
      apiPostSubAccountChange({ id: this.updateId, isDel: true }).then(res => {
        console.log(res)
        if (res.data.data) {
          this.$message({type:'success', message: this.$t('ibProfile.sharedIbList.sharedAccountDeleted')})
          this.$emit('refreshCard');
          this.$emit('update:isDeleteVisible', false);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  padding: 40px 32px;
  max-width: 448px;
  .el-dialog__header {
    display: none;
    .el-dialog__headerbtn{
      display: block;
    }
  }
  .el-dialog__body {
    padding: 0;
  }
}

.btn_group {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;

  .el-button {
    width: 100%;
    font-size: 16px;
    height: 40px;
    padding: 0;
    &:first-child {
      background-color: #fff;
      margin-right: 24px;
    }
  }
}
</style>
