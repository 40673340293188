<template>
   <el-dialog :visible="fundsCheckVisible" destroy-on-close  top="1vh">
    <div style="text-align: center;">{{ $t('ibProfile.updateSharedIb.fundsChangeTips')}}</div>
    <div class="btn_group">
      <el-button @click="cancel">{{ $t('ibProfile.updateSharedIb.cancel')}}</el-button>
      <el-button @click="$emit('update:fundsCheckVisible', false)" type="primary">{{ $t('ibProfile.updateSharedIb.confirm')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    fundsCheckVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    cancel() {
      this.$emit('cancelChange');
      this.$emit('update:fundsCheckVisible', false);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  padding: 40px 32px;
  max-width: 448px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.btn_group {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;

  .el-button {
    width: 100%;
    font-size: 16px;
    height: 40px;
    padding: 0;
    &:first-child {
      background-color: #fff;
      margin-right: 24px;
    }
  }
}
</style>
