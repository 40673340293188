<template>
  <div>
    <el-dialog
      :visible="isUpdateVisible"
      :title="title"
      @close="onClose"
      :close-on-click-modal="false"
      destroy-on-close
      class="update_wrapper"
      top="1vh"
    >
      <el-form ref="updateForm" :rules="formRules" :model="form" autocomplete="off">
        <el-form-item :label="$t('ibProfile.updateSharedIb.email')" prop="email">
          <el-input maxLength="80" :placeholder="$t('ibProfile.updateSharedIb.emailPlaceholder')" v-model="form.email" clearable />
        </el-form-item>
        <el-form-item :label="$t('ibProfile.updateSharedIb.password')" prop="password" v-if="!updateId">
          <el-tooltip effect="dark" placement="top-start"  popper-class="linkTooltip">
            <div slot="content">
              <ul style="width: 200px; line-height: normal">
                <li>1.{{ $t('ibProfile.updateSharedIb.passwordValidateNum') }}</li>
                <li>2.{{ $t('ibProfile.updateSharedIb.passwordValidateTips') }}</li>
              </ul>
            </div>
            <img src="@/assets/images/profile/tips.png" width="12px" alt="" style="vertical-align: inherit;"/>
          </el-tooltip>
          <el-input
            type="password"
            v-model="form.password"
            show-password
            :placeholder="$t('ibProfile.updateSharedIb.passwordValidate')"
            autocomplete="new-password"
            clearable
          />
        </el-form-item>
        <div style="margin:10px 0 12px 0; display: flex; align-items: center;">
          <div style="padding: 0 8px 0 0;">{{ $t('ibProfile.updateSharedIb.sharedIbAccountPermission') }}</div>
          <el-tooltip effect="dark" placement="top-start" popper-class="linkTooltip">
            <div slot="content">
              <ul style="line-height: normal">
                <div>{{ $t('ibProfile.updateSharedIb.permissionIdsTips') }}</div>
                <li v-html="$t('ibProfile.updateSharedIb.permissionIdsFirst')" />
                <li v-html="$t('ibProfile.updateSharedIb.permissionIdsSecond')" />
                <li v-html="$t('ibProfile.updateSharedIb.permissionIdsThird')" />
              </ul>
            </div>
            <img src="@/assets/images/profile/tips.png" width="12px" height="12px" alt="" />
          </el-tooltip>
        </div>
        <el-form-item label="" prop="permissionIds">
          <el-checkbox-group v-model="form.permissionIds" class="permissionsIds">
            <el-checkbox
              v-for="item in permissionsOpts"
              :key="item.value"
              :label="item.value"
              name="permissionIds"
              @change="boolean => checkboxChange(boolean, item.value)"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item :label="$t('ibProfile.updateSharedIb.remark')" prop="remark"
          ><el-input
            type="textarea"
            :rows="3"
            :placeholder="$t('ibProfile.updateSharedIb.remarkTips')"
            maxlength="100"
            v-model="form.remark"
            show-word-limit
          />
        </el-form-item>
        <el-form-item :label="$t('ibProfile.updateSharedIb.status')" props="enabled">
          <el-switch v-model="form.enabled" style="margin-right: 8px;" />
          <span>{{
            form.enabled ? $t('ibProfile.updateSharedIb.enabled') : $t('ibProfile.updateSharedIb.disabled')
          }}</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="btn_submit">
        <el-button @click="onClose">{{ $t('ibProfile.updateSharedIb.cancel') }}</el-button>
        <el-button type="primary" @click="onSubmit">{{ $t('ibProfile.updateSharedIb.confirm') }}</el-button>
      </div>
    </el-dialog>
    <FundsCheckedDialog :fundsCheckVisible.sync="fundsCheckVisible" @cancelChange="cancelChange" />
  </div>
</template>

<script>
import { apiUpdateSubAccount } from '@/resource';
import { md5, rsa } from '@/util/encrypt.js';
import FundsCheckedDialog from './FundsCheckedDialog';

export default {
  components: { FundsCheckedDialog },
  props: {
    isUpdateVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    updateId: {
      type: String | Number,
      default: ''
    },
    formFromSharedAccount: {
      type: Object,
      default: {}
    }
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('ibProfile.updateSharedIb.passwordValidate')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*.()])(?=.*[0-9])(?=.*[a-z]).{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('ibProfile.updateSharedIb.passwordValidateWarning')));
        } else {
          callback();
        }
      }
    };
    return {
      form: {
        email: '',
        password: '',
        permissionIds: [],
        remark: '',
        enabled: false
      },
      fundsCheckVisible: false,
      formRules: {
        email: [
          { required: true, type: 'email', message: this.$t('ibProfile.updateSharedIb.emailValidate') },
        ],
        password: [{ required: true, validator: validatePassword, trigger: 'blur' }]
      },
      permissionsOpts: [
        { label: this.$t('ibProfile.sharedIbList.accountData'), value: 1 },
        { label: this.$t('ibProfile.sharedIbList.IBData'), value: 2 },
        { label: this.$t('ibProfile.sharedIbList.fundOperations'), value: 3 }
      ]
    };
  },

  watch: {
    isUpdateVisible: {
      handler(val) {
        val && this.updateId
          ? (this.form = this.formFromSharedAccount)
          : (this.form = { email: '', password: '', permissionIds: [], remark: '', enabled: false});
      },
      immediate: true
    }
  },
  methods: {
    checkboxChange(boolean, value) {
      if (value === 3 && boolean) {
        this.fundsCheckVisible = true;
      }
    },
    cancelChange() {
      this.form.permissionIds.pop();
    },
    onClose() {
      this.$refs['updateForm'].resetFields();
      this.$emit('update:isUpdateVisible', false);
    },
    onSubmit() {
      this.$refs['updateForm'].validate(valid => {
        if (valid) {
          const formData = {
            ...this.form,
            id: this.updateId ? this.updateId : '',
            email: rsa(this.form.email),
            password: this.updateId ? '' : md5(this.form.password)
          };
          apiUpdateSubAccount(formData).then(res => {
            console.log(res.data.data)
            if (res.data.data) {
              this.$emit('update:isUpdateVisible', false);
              this.updateId ? this.$message({ type: 'success', message: this.$t('ibProfile.updateSharedIb.editSuccess') }) : this.$message({ type: 'success', message: this.$t('ibProfile.updateSharedIb.addSuccess') });
              this.$emit('refreshCard');
            } else if(res.data.code === 0 && !res.data.data) {
              this.$message({ type: 'error', message: this.$t('ibProfile.updateSharedIb.emailError') });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/ibProfile/subUpdateDialog.scss';
</style>
