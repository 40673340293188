<template>
  <div id="settings">
    <div class="main">
      <div class="title">
        <img class="icon" src="@/assets/images/myProfile/icon.png" alt="" />
        <h3>{{ $t('menu.ibProfile') }}</h3>
        <img class="line" src="@/assets/images/myProfile/line.png" alt="" />
      </div>
      <div class="myProfile-box">
        <table>
          <tr>
            <td><img src="@/assets/images/myProfile/accountType.png" alt="" />{{ $t('common.keys.ACCTYPE') }}</td>
            <td v-html="$store.state.common.isSubUser ? '***' : accountType[clientProfileData.accountType]"></td>
          </tr>
          <br />
          <tr>
            <td><img src="@/assets/images/myProfile/name.png" alt="" />{{ $t('common.keys.NAME') }}</td>
            <td v-html="$store.state.common.isSubUser ? '***' : clientProfileData.name"></td>
          </tr>
          <br />
          <tr>
            <td><img src="@/assets/images/myProfile/address.png" alt="" />{{ $t('common.field.emailAdd') }}</td>
            <td v-html="$store.state.common.isSubUser ? '***' : clientProfileData.emailAddress"></td>
          </tr>
          <br />
          <tr>
            <td><img src="@/assets/images/myProfile/phone.png" alt="" />{{ $t('common.field.phone') }}</td>
            <td v-html="$store.state.common.isSubUser ? '***' : clientProfileData.phone"></td>
          </tr>
          <br />
          <tr>
            <td><img src="@/assets/images/myProfile/nationality.png" alt="" />{{ $t('common.field.nat') }}</td>
            <td v-html="$store.state.common.isSubUser ? '***' : clientProfileData.nationality"></td>
          </tr>
          <br />
          <tr>
            <td><img src="@/assets/images/myProfile/country.png" alt="" />{{ $t('common.field.country') }}</td>
            <td v-html="$store.state.common.isSubUser ? '***' : clientProfileData.country"></td>
          </tr>
          <br />
          <tr>
            <td><img src="@/assets/images/myProfile/address.png" alt="" />{{ $t('common.field.address') }}</td>
            <td v-html="$store.state.common.isSubUser ? '***' : clientProfileData.streetAddress"></td>
          </tr>
          <br />
          <tr>
            <td><img src="@/assets/images/myProfile/saving.png" alt="" />{{ $t('common.field.save') }}</td>
            <td v-html="$store.state.common.isSubUser ? '***' : clientProfileData.savingsInvestment"></td>
          </tr>
          <br />
          <tr>
            <td><img src="@/assets/images/myProfile/register.png" alt="" />{{ $t('common.field.annIncome') }}</td>
            <td v-html="$store.state.common.isSubUser ? '***' : clientProfileData.averageIncome"></td>
          </tr>
        </table>
      </div>
      <div class="info">
        <i18n path="myProfile.changeDetails" tag="p">
          <template v-slot:mailTo>
            <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
          </template>
        </i18n>
      </div>
    </div>
  </div>
</template>

<script>
import { apiInfo } from '@/resource';

export default {
  data() {
    return {
      clientProfileData: {
        accountType: '',
        name: '',
        emailAddress: '',
        phone: '',
        dateBirth: '',
        nationality: '',
        country: '',
        streetAddress: '',
        suburb: '',
        state: '',
        postcode: '',
        employmentStatus: '',
        averageIncome: '',
        savingsInvestment: '',
        sourceOfFunds: ''
      },
      accountType: {
        1: 'Demo Account',
        2: 'Personal Account',
        3: 'Master Joint Account',
        4: 'Vice Joint Account',
        5: 'IB Individual Account',
        6: 'Internal Account',
        7: 'Experience Account',
        8: 'Leads Account',
        9: 'Repetitive Leads Account',
        10: 'Imported Leads Account',
        11: 'Irregular Leads Account',
        13: 'Company Account',
        14: 'SMSF Account',
        15: 'Vice Leads Account',
        16: 'IB Company Account',
        17: 'Migration Leads Account',
        18: 'Migration Demo Account',
        19: 'Conflict Account'
      }
    };
  },
  mounted() {
    apiInfo().then(resp => {
      if (resp.data.code == 0) this.clientProfileData = resp.data.data;
    });
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/settings.scss';
</style>
