<template>
  <el-dialog
    :visible="isResetVisible"
    :title="$t('ibProfile.updateSharedIb.resetPassword')"
    destroy-on-close
    top="1vh"
    @close="closeDialog"
  >
    <el-form ref="resetPasswordForm" :rules="formRules" :model="form" autocomplete="off">
      <el-form-item :label="$t('ibProfile.updateSharedIb.enterNewPassword')" prop="password">
        <el-tooltip effect="dark" placement="top-start"  popper-class="linkTooltip">
          <div slot="content">
            <ul style="width: 200px;line-height: normal ">
              <li>{{ $t('ibProfile.updateSharedIb.changePasswordTips') }}</li>
            </ul>
          </div>
          <img src="@/assets/images/profile/tips.png" width="12px" style="margin-left: 8px;"  alt="" />
        </el-tooltip>
        <el-input show-password v-model="form.password" type="password" autocomplete="new-password" clearable />
      </el-form-item>
    </el-form>
    <div class="btn_group">
      <el-button @click="closeDialog">{{ $t('ibProfile.updateSharedIb.cancel') }}</el-button>
      <el-button @click="confirmChange" type="primary">{{ $t('ibProfile.updateSharedIb.confirm') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { apiPostSubPasswordChange } from '@/resource';
import { md5 } from '@/util/encrypt.js';
export default {
  props: {
    isResetVisible: {
      type: Boolean,
      default: false
    },
    updateId: {
      type: Number | String,
      default: ''
    }
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('ibProfile.updateSharedIb.passwordValidate')));
      } else {
        const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*.()])(?=.*[0-9])(?=.*[a-z]).{8,16}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('ibProfile.updateSharedIb.passwordValidateWarning')));
        } else {
          callback();
        }
      }
    };
    return {
      form: {
        password: ''
      },
      formRules: {
        password: [{ required: true, validator: validatePassword, trigger: 'blur' }]
      }
    };
  },
  methods: {
    closeDialog(){
      this.$emit('update:isResetVisible', false)
      this.form.password = ''
    },
    confirmChange() {
      this.$refs['resetPasswordForm'].validate(valid => {
        if (valid) {
          apiPostSubPasswordChange({ id: this.updateId, password: md5(this.form.password) }).then(res => {
            if (res.data.data) {
              this.$message.success(this.$t('ibProfile.updateSharedIb.resetPasswordSuccess'));
            }
            this.closeDialog()
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  padding: 40px 32px;
  max-width: 448px;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-form-item__label {
    line-height: 42px;
  }
  .el-form{
    margin-top: 20px;
  }
  .el-form-item__label{
    color: #1D1D1F;
    font-size: 14px;
  }
  .el-input .el-input__inner{
    height: 40px;
    line-height: 40px;
  }
}
.btn_group {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;

  .el-button {
    width: 100%;
    font-size: 16px;
    height: 40px;
    padding: 0;
    &:first-child {
      background-color: #fff;
      margin-right: 24px;
    }
  }
}

@media (max-width: 768px) {
  .btn_group {
    margin-top: 46px;

  }
}
</style>
