<template>
  <div class="wrapper-main">
    <div class="wrapper-content">
      <el-tabs v-model="activeName"  class="tab_wrapper"  v-if="!$store.state.common.isSubUser && $store.state.common.subUserSwitch">
        <el-tab-pane  :label="$t('menu.ibProfile')" :stretch="true" name="profile" />
        <el-tab-pane :label="$t('ibProfile.sharedIbList.sharedIbAccount')" :stretch="true" name="sharedAccount" />
      </el-tabs>
      <component :is="tabComponent" />
    </div>
  </div>
</template>

<script>
import Settings from './Settings.vue';
import SharedIbAccount from './SharedIbAccounts.vue';
export default {
  components: { Settings, SharedIbAccount },
  data() {
    return {
      activeName: 'profile'
    };
  },
  computed: {
    tabComponent() {
      const components = {
        profile: Settings,
        sharedAccount: SharedIbAccount
      };
      return components[this.activeName] || Settings;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__header {
  margin: 0;
}
.tab_wrapper {
  
  /deep/ .el-tabs__active-bar {
    background-color: transparent;
  }

  /deep/ .el-tabs__item {
    width: 169px;
    height: 39px;
    background-color: #b4c5e5;
    line-height: 39px;
    text-align: center;
    font-weight: 700;
    font-size: 13px;
    color: #004cb2;
    padding: 0;
    margin-right: 4px;
    border-radius: 4px 4px 0 0 ;
  }
  /deep/ .is-active {
    width: 169px;
    height: 46px;
    font-size: 13px;
    text-align: center;
    font-style: normal;
    line-height: 46px;
    color: $white;
    background-color: #004cb2;
    // margin-bottom: -22px;
    vertical-align: bottom;
    box-shadow: 2.007px 2.229px 7.56px 1.44px #0914574f;
  }
}
@media (max-width:768px) {
  /deep/  .tab_wrapper {
    margin-bottom: 10px;
    width: 100%;
    .el-tabs__nav{
      width: 100%;
    }
   .el-tabs__item{
      width:calc((100% - 5px) / 2);
      height: 35px;
      line-height: 35px;
      font-size: 12px;
      font-weight: inherit;
      margin-bottom: -5px;
    }
    .is-active{
      height: 49px;
      line-height: 49px;
      // margin-bottom: -10px;
    }
  }
}

</style>
