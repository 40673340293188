<template>
  <div class="sub_profile_wrapper">
    <div class="header">
      <div class="header-tips">{{ $t('ibProfile.sharedIbList.maxSharedIbAccount') }}</div>
      <div class="header-btn">
        <el-button type="primary" @click="addNewAccount">{{ $t('ibProfile.sharedIbList.add') }}</el-button>
        <el-button @click="() => (this.isRecordVisible = true)">{{
          $t('ibProfile.sharedIbList.accountHistoryBtn')
        }}</el-button>
      </div>
    </div>
    <div class="card_wrapper">
        <div class="card_item" v-for="(item, index) in cardDataNoDel" :key="item.id">
            <el-card shadow="never">
              <div class="card_wrapper_top">
                <div class="card_left">
                  <div class="card_copy_group" @click="onCopy(item.email)" >
                    <el-tooltip effect="light" :visible-arrow="false" placement="top">
                      <div slot="content" style="max-width: 200px;">{{ item.email }}</div>
                      <div :class="`email_show${item.enabled ? '' : '_disabled'}`">{{ item.email }}</div>
                    </el-tooltip>
                    <div class="icon_copy" />
                  </div>

                  <div :class="`mark${item.enabled ? '' : '_disabled'}`">
                    {{ $t('ibProfile.sharedIbList.remark') }}:
                    <el-tooltip effect="light" :visible-arrow="false" placement="bottom-start">
                      <div slot="content" style="max-width: 200px;">{{ item.remark }}</div>
                      <span>{{ item.remark }}</span>
                    </el-tooltip>
                  </div>
                </div>
                <el-switch :value="item.enabled" @change="value => changeEnabled(value, item.id, index)" />
              </div>
              <div class="card_wrapper_bottom">
                <div :class="`card_icon_group${isInclude(item.permissionIds, 1, item.enabled)}`">
                  <div class="icon_customer" />
                  <div>{{ $t('ibProfile.sharedIbList.accountData') }}</div>
                </div>
                <div :class="`card_icon_group${isInclude(item.permissionIds, 2, item.enabled)}`">
                  <div class="icon_data" />
                  <div>{{ $t('ibProfile.sharedIbList.IBData') }}</div>
                </div>
                <div :class="`card_icon_group${isInclude(item.permissionIds, 3, item.enabled)}`">
                  <div class="icon_wallet" />
                  <div>{{ $t('ibProfile.sharedIbList.fundOperations') }}</div>
                </div>
                <div class="line" />
                <el-popover placement="top-start" trigger="click">
                  <div class="setting_group">
                    <div class="setting" @click="updateSharedAccount(item)">
                      <div class="icon_file" />
                      <div>{{ $t('ibProfile.sharedIbList.setting') }}</div>
                    </div>
                    <div class="password_change" @click="resetSharedAccount(item.id)">
                      <div class="icon_password" />
                      <div>{{ $t('ibProfile.sharedIbList.resetPassword') }}</div>
                    </div>
                    <div class="delete" @click="deleteSharedAccount(item.id)">
                      <div class="icon_delete" />
                      <div>{{ $t('ibProfile.sharedIbList.delete') }}</div>
                    </div>
                  </div>
                  <div class="icon_setting" slot="reference" />
                </el-popover>
              </div>
            </el-card>
        </div>
    </div>
    <SubUpdateDialog :isUpdateVisible.sync="isUpdateVisible" :title="title" @refreshCard="getSubAccountList"
      :updateId="updateId" :formFromSharedAccount="form" />
    <SubRecordDialog :isRecordVisible.sync="isRecordVisible" :title="$t('ibProfile.sharedIbList.accountHistory')"
      :sharedIbAccount="this.cardData" ref="recordDialog" />
    <DeleteDialog :isDeleteVisible.sync="isDeleteVisible" :updateId="updateId" @refreshCard="getSubAccountList" />
    <ResetPasswordDialog :isResetVisible.sync="isResetVisible" :updateId="updateId" />
  </div>
</template>

<script>
import SubUpdateDialog from '@/components/ibProfile/SubUpdateDialog';
import SubRecordDialog from '@/components/ibProfile/SubRecordDialog';
import DeleteDialog from '@/components/ibProfile/DeleteDialog';
import ResetPasswordDialog from '@/components/ibProfile/ResetPasswordDialog';
import { apiGetSubAccountList, apiPostSubAccountChange } from '@/resource';
export default {
  components: { SubUpdateDialog, SubRecordDialog, DeleteDialog, ResetPasswordDialog },
  data() {
    return {
      title: this.$t('ibProfile.updateSharedIb.addNewSharedIb'),
      isUpdateVisible: false,
      isRecordVisible: false,
      isDeleteVisible: false,
      isEnableVisible: false,
      isResetVisible: false,
      enabledIndex: 0,
      updateId: '',
      cardData: [],
      cardDataNoDel:[],
      form: {}
    };
  },
  methods: {
    deleteSharedAccount(id) {
      this.isDeleteVisible = true;
      this.updateId = id;
    },
    changeEnabled(value, id, index) {
      apiPostSubAccountChange({ enabled: value, id }).then(res => {
        if (res.data.data) {
          this.cardDataNoDel[index].enabled = value;
          value
            ? this.$message.success(this.$t('ibProfile.sharedIbList.sharedAccountEnabled'))
            : this.$message.success(this.$t('ibProfile.sharedIbList.sharedAccountDisabled'));
        }
      });
    },
    onCopy(item) {
      this.$copyText(item)
        .then(result => {
          this.$message.success(this.$t('ibProfile.sharedIbList.copyTips'));
          console.log('Copied :' + item);
        })
        .catch(err => {
          this.$message('Failed to copy' + err);
          console.log(err);
        });
    },
    addNewAccount() {
      this.updateId = '';
      this.title = this.$t('ibProfile.updateSharedIb.addNewSharedIb');
      this.isUpdateVisible = true;
    },
    updateSharedAccount(item) {
      this.updateId = item.id;
      this.title = this.$t('ibProfile.updateSharedIb.editSharedIb');
      this.form = {
        email: item.email,
        remark: item.remark,
        permissionIds: item.permissionIds,
        enabled: item.enabled
      };
      this.isUpdateVisible = true;
    },
    getSubAccountList() {
      apiGetSubAccountList().then(res => {
        if (res.data.code === 0) {
          this.cardData = res.data.data;
          this.cardDataNoDel = this.cardData.filter(item=>!item.isDel)
        }
      });
    },
    isInclude(permissionsIds, number, enabled) {
      return permissionsIds.includes(number) && enabled ? '' : '_disabled';
    },
    resetSharedAccount(id) {
      this.updateId = id;
      this.isResetVisible = true;
    }
  },
  mounted() {
    this.getSubAccountList();
  }
};
</script>

<style lang="scss">
.el-popover {
  min-width: auto;
}
</style>

<style lang="scss" scoped>
@import '@/assets/css/components/ibProfile/subProfile.scss';
</style>
