import { render, staticRenderFns } from "./SubRecordDialog.vue?vue&type=template&id=7c542174&scoped=true&"
import script from "./SubRecordDialog.vue?vue&type=script&lang=js&"
export * from "./SubRecordDialog.vue?vue&type=script&lang=js&"
import style0 from "./SubRecordDialog.vue?vue&type=style&index=0&id=7c542174&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c542174",
  null
  
)

export default component.exports